/* import the necessary Bootstrap files */


  /* -------begin customization-------- */  
$body-bg: #e9f3f9;
$primary: #c0000d;
$secondary: #6f7891;
$light: #f5f5f1;
$dark: #335496;


/* -------end customization-------- */ 
@import "~bootstrap/scss/bootstrap.scss";
